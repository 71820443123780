export default class IlsaFilters {
  referenceNr?: string
  isUsed?: boolean
  model?: string
  colour?: string
  odometerMin?: number
  odometerMax?: number
  priceMin?: number
  priceMax?: number
  discount?: boolean
  constructionYearMin?: number
  constructionYearMax?: number
  keyword?: string
  postalCode?: string
  distanceMax?: number
  order?: string
  sortSeed?: string

  public static readonly REFERENCE_NR: string = 'identification.referencenumber'

  public static readonly IS_USED: string = 'condition.used'

  public static readonly MODEL: string = 'general.model.normalized'
  public static readonly COLOUR: string = 'body.colour.primary'
  public static readonly KEYWORD: string = 'keyword'

  public static readonly ORDER: string = '_order'
  public static readonly ORDER_DEFAULT: string = 'default'
  public static readonly SORTSEED: string = '_sort_seed'
  public static readonly SORTSEED_DEFAULT: string = 'cupra' // Totally random string

  // Range fields
  public static readonly ODOMETER: string = 'condition.odometer.value_in_km'
  public static readonly PRICE: string = 'sales_conditions.pricing.asking.general.in_eur.amount'
  public static readonly DISCOUNT: string =
    'sales_conditions.pricing.asking.general.in_eur.is_discounted'
  public static readonly CONSTRUCTION_YEAR: string = 'general.year'
  public static readonly DISTANCE: string = 'advertiser.coordinates'

  public static readonly DROPDOWN_FILTERS = [
    this.MODEL,
    this.COLOUR,
    this.ODOMETER,
    this.PRICE,
    this.CONSTRUCTION_YEAR,
    this.DISTANCE,
    this.DISCOUNT
  ]

  constructor({
    referenceNr,
    isUsed,
    model,
    colour,
    odometerMin,
    odometerMax,
    priceMin,
    priceMax,
    discount,
    constructionYearMin,
    constructionYearMax,
    keyword,
    postalCode,
    distanceMax,
    order,
    sortSeed
  }: {
    referenceNr?: string
    isUsed?: boolean
    model?: string
    colour?: string
    odometerMin?: number
    odometerMax?: number
    priceMin?: number
    priceMax?: number
    discount?: boolean
    constructionYearMin?: number
    constructionYearMax?: number
    keyword?: string
    postalCode?: string
    distanceMax?: number
    order?: string
    sortSeed?: string
  }) {
    this.referenceNr = referenceNr
    this.isUsed = isUsed
    this.model = model
    this.colour = colour
    this.odometerMin = odometerMin
    this.odometerMax = odometerMax
    this.priceMin = priceMin
    this.priceMax = priceMax
    this.discount = discount
    this.constructionYearMin = constructionYearMin
    this.constructionYearMax = constructionYearMax
    this.keyword = keyword
    this.postalCode = postalCode
    this.distanceMax = distanceMax
    this.order = order
    this.sortSeed = sortSeed
  }

  params(withOrder: boolean = true) {
    return {
      ...(this.referenceNr ? { [IlsaFilters.REFERENCE_NR]: this.referenceNr } : {}),
      ...(this.isUsed !== null ? { [IlsaFilters.IS_USED]: this.isUsed } : {}),
      ...(this.model ? { [IlsaFilters.MODEL]: this.model } : {}),
      ...(this.colour ? { [IlsaFilters.COLOUR]: this.colour } : {}),
      ...(this.odometerMin || this.odometerMax
        ? { [IlsaFilters.ODOMETER]: `${this.odometerMin || ''}-${this.odometerMax || ''}` }
        : {}),
      ...(this.priceMin || this.priceMax
        ? { [IlsaFilters.PRICE]: `${this.priceMin || ''}-${this.priceMax || ''}` }
        : {}),
      ...(this.discount ? { [IlsaFilters.DISCOUNT]: true } : {}),
      ...(this.constructionYearMin || this.constructionYearMax
        ? {
            [IlsaFilters.CONSTRUCTION_YEAR]: `${this.constructionYearMin || ''}-${this.constructionYearMax || ''}`
          }
        : {}),
      ...(this.keyword ? { [IlsaFilters.KEYWORD]: this.keyword } : {}),
      ...(this.distanceMax ? { [IlsaFilters.DISTANCE]: `${this.distanceMax}` } : {}),
      ...(withOrder
        ? {
            [IlsaFilters.ORDER]: this.order || IlsaFilters.ORDER_DEFAULT,
            [IlsaFilters.SORTSEED]: this.sortSeed || IlsaFilters.SORTSEED_DEFAULT
          }
        : {})
    }
  }
}
