import IlsaFilters from '@/services/IlsaService/IlsaFilters'

export interface DropDownOption {
  key: string
  display_value: string
  count: number
}

export class DropDownContentsResponse {
  models: Array<DropDownOption>
  colours: Array<DropDownOption>
  odometerMin: Array<DropDownOption>
  odometerMax: Array<DropDownOption>
  priceMin: Array<DropDownOption>
  priceMax: Array<DropDownOption>
  constructionYearMin: Array<DropDownOption>
  constructionYearMax: Array<DropDownOption>
  distanceMax: Array<DropDownOption>
  isDiscounted: number

  constructor(json: any) {
    this.models = json.fields[IlsaFilters.MODEL]
    this.colours = json.fields[IlsaFilters.COLOUR]
    this.distanceMax = json.fields[IlsaFilters.DISTANCE]

    // Range fields
    this.odometerMin = json.range_fields[IlsaFilters.ODOMETER].min
    this.odometerMax = json.range_fields[IlsaFilters.ODOMETER].max
    this.priceMin = json.range_fields[IlsaFilters.PRICE].min
    this.priceMax = json.range_fields[IlsaFilters.PRICE].max
    this.constructionYearMin = json.range_fields[IlsaFilters.CONSTRUCTION_YEAR].min
    this.constructionYearMax = json.range_fields[IlsaFilters.CONSTRUCTION_YEAR].max

    this.isDiscounted = json.bool_fields[IlsaFilters.DISCOUNT].True
  }
}
