<script setup lang="ts">
const props = defineProps({
  error: {
    type: Number,
    required: false
  }
})
</script>

<template>
  <div v-if="props.error" class="container mx-auto mt-4 p-4 bg-red-300">
    <template v-if="props.error === 404">
      Deze auto kon niet worden gevonden. Misschien is de auto al verkocht?

      <a href="/" class="text-petrol underline">Terug naar het overzicht</a>

      <div class="mt-4 text-xs">404 - Page not found</div>
    </template>
    <template v-else>
      Er ging iets fout bij het ophalen van de gegevens. Probeer het later opnieuw.
    </template>
  </div>
</template>
