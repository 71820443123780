import { computed, ref, watch } from 'vue'
import { defineStore } from 'pinia'
import IlsaFilters from '@/services/IlsaService/IlsaFilters'
import { useRoute, useRouter, type RouteLocationNormalizedLoaded } from 'vue-router'
import config, { AppMode } from '@/config'

export const useFiltersStore = defineStore('filters', () => {
  const model = ref<string>()
  const colour = ref<string>()
  const odometerMin = ref<number>()
  const odometerMax = ref<number>()
  const priceMin = ref<number>()
  const priceMax = ref<number>()
  const discount = ref<boolean>(false)
  const constructionYearMin = ref<number>()
  const constructionYearMax = ref<number>()
  const postalCode = ref<string>('')
  const keyword = ref<string>('')
  const distanceMax = ref<number>()
  const order = ref<string>()

  const sortSeed = ref<string>(
    Math.random().toString(20).substring(2, 5) + Math.random().toString(20).substring(2, 5)
  )

  const isFiltering = computed(
    () =>
      model.value ||
      colour.value ||
      odometerMin.value ||
      odometerMax.value ||
      priceMin.value ||
      priceMax.value ||
      discount.value ||
      constructionYearMin.value ||
      constructionYearMax.value ||
      keyword.value ||
      distanceMax.value
  )

  const filters = computed(
    () =>
      new IlsaFilters({
        isUsed: config.appMode === AppMode.Used,
        model: model.value,
        colour: colour.value,
        odometerMin: odometerMin.value,
        odometerMax: odometerMax.value,
        priceMin: priceMin.value,
        priceMax: priceMax.value,
        discount: discount.value,
        constructionYearMin: constructionYearMin.value,
        constructionYearMax: constructionYearMax.value,
        keyword: keyword.value,
        postalCode: postalCode.value,
        distanceMax: distanceMax.value,
        order: order.value,
        sortSeed: sortSeed.value
      })
  )

  const router = useRouter()
  const route = useRoute()

  // Parse route to filters
  const readRoute = (route: RouteLocationNormalizedLoaded) => {
    if (route.params.model) {
      model.value = 'cupra/' + (route.params.model as string)
    } else {
      model.value = undefined
    }
    colour.value = route.query.colour ? (route.query.colour as string) : undefined
    odometerMin.value = route.query.odometer_min
      ? parseInt(route.query.odometer_min as string)
      : undefined
    odometerMax.value = route.query.odometer_max
      ? parseInt(route.query.odometer_max as string)
      : undefined
    priceMin.value = route.query.price_min ? parseInt(route.query.price_min as string) : undefined
    priceMax.value = route.query.price_max ? parseInt(route.query.price_max as string) : undefined
    discount.value = !!route.query.discount
    constructionYearMin.value = route.query.construction_year_min
      ? parseInt(route.query.construction_year_min as string)
      : undefined
    constructionYearMax.value = route.query.construction_year_max
      ? parseInt(route.query.construction_year_max as string)
      : undefined
    postalCode.value = route.query.postal_code ? (route.query.postal_code as string) : ''
    distanceMax.value = route.query.distance_max
      ? parseInt(route.query.distance_max as string)
      : undefined
    keyword.value = route.query.keyword ? (route.query.keyword as string) : ''
    order.value = route.query.order ? (route.query.order as string) : undefined
  }

  // Read current route
  readRoute(route)

  // Monitor future route changes to update filters
  watch(route, (to) => {
    if (to.name === 'list') readRoute(to)
  })

  const utmTags = computed(() =>
    Object.keys(route.query).reduce(
      (acc, key) => {
        if (key.match(/^utm_/) && typeof route.query[key] === 'string') {
          acc[key] = route.query[key]
        }
        return acc
      },
      {} as Record<string, string>
    )
  )

  const urlFromFilters = (filters: IlsaFilters) => {
    return {
      name: 'list',
      params: {
        ...(filters.model ? { model: filters.model.replace('cupra/', '') } : {})
      },
      query: {
        ...utmTags.value,
        ...(filters.colour ? { colour: filters.colour } : {}),
        ...(filters.odometerMin ? { odometer_min: filters.odometerMin } : {}),
        ...(filters.odometerMax ? { odometer_max: filters.odometerMax } : {}),
        ...(filters.priceMin ? { price_min: filters.priceMin } : {}),
        ...(filters.priceMax ? { price_max: filters.priceMax } : {}),
        ...(filters.discount ? { discount: 'yes' } : {}),
        ...(filters.constructionYearMin
          ? { construction_year_min: filters.constructionYearMin }
          : {}),
        ...(filters.constructionYearMax
          ? { construction_year_max: filters.constructionYearMax }
          : {}),
        ...(filters.postalCode ? { postal_code: filters.postalCode } : {}),
        ...(filters.distanceMax ? { distance_max: filters.distanceMax } : {}),
        ...(filters.keyword ? { keyword: filters.keyword } : {}),
        ...(filters.order ? { order: filters.order } : {})
      }
    }
  }
  const getListUrlWithFilters = () => urlFromFilters(filters.value)

  // Monitor future filter changes to update route
  watch(filters, (currentFilters) => router.push(urlFromFilters(currentFilters)))

  return {
    model,
    colour,
    odometerMin,
    odometerMax,
    priceMin,
    priceMax,
    discount,
    constructionYearMin,
    constructionYearMax,
    postalCode,
    distanceMax,
    keyword,
    isFiltering,
    filters,
    order,
    getListUrlWithFilters
  }
})
