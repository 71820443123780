<script setup lang="ts">
import { computed, ref, watch } from 'vue'
import debounce from 'lodash.debounce'
import { useFiltersStore } from '@/stores/filters'
import { useIlsaStore } from '@/stores/ilsa'
import { storeToRefs } from 'pinia'
import config, { AppMode } from '@/config'
import ModelPicker from './ModelPicker.vue'

const appMode = config.appMode

const ilsaStore = useIlsaStore()
const filtersStore = useFiltersStore()

const {
  model: filterModel,
  colour: filterColour,
  odometerMax: filterOdometerMax,
  priceMax: filterPriceMax,
  discount: filterDiscount,
  constructionYearMin: filterConstructionYearMin,
  keyword: filterKeyword,
  postalCode: filterPostalCode,
  distanceMax: filterDistanceMax
} = storeToRefs(filtersStore)

const keyword = ref(filtersStore.keyword)
watch(
  keyword,
  debounce(() => {
    filterKeyword.value = keyword.value
  }, 300)
)

const postalCode = ref(filtersStore.postalCode)
watch(
  postalCode,
  debounce(() => {
    if (postalCode.value.length === 4) {
      filterPostalCode.value = postalCode.value
    } else {
      filterPostalCode.value = ''
    }
  }, 300)
)

const hasDiscountedCars = computed(() => (ilsaStore.dropDownContents?.isDiscounted || 0) > 0)

filtersStore.$subscribe(() => ilsaStore.retrieveSearchResults())

const gridCols = computed(() => {
  if (appMode !== AppMode.New || hasDiscountedCars.value) {
    return 'md:grid-cols-2 lg:grid-cols-4'
  } else {
    return 'md:grid-cols-3'
  }
})
</script>

<style>
option {
  color: black; /* this fixes white on white options in select boxes on Windows */
}
</style>

<template>
  <div v-if="appMode === AppMode.New" class="mb-8 xl:mx-32">
    <ModelPicker v-model="filterModel" :car-models="ilsaStore.dropDownContents!.models" />
  </div>

  <div
    class="grid grid-cols-1 gap-6 lg:gap-6 text-cupgragray items-end text-sm lg:text-md"
    :class="gridCols"
  >
    <div v-if="appMode !== AppMode.New" class="flex flex-col gap-1">
      <label for="model">Model</label>
      <select
        id="model"
        v-model="filterModel"
        class="bg-white border-cupragray focus:ring-copper focus:border-copper"
      >
        <option :value="undefined">Geen voorkeur</option>
        <option
          v-for="(option, ix) in ilsaStore.dropDownContents?.models"
          :key="ix"
          :value="option.key"
          :disabled="!option.count"
        >
          CUPRA {{ option.display_value }} ({{ option.count }})
        </option>
      </select>
    </div>

    <div v-if="appMode === AppMode.Used" class="flex flex-col gap-1">
      <label for="odometer_max">Kilometerstand tot</label>
      <select
        id="odometer_max"
        v-model="filterOdometerMax"
        class="bg-white border-cupgragray focus:ring-copper focus:border-copper"
      >
        <option :value="undefined">Geen voorkeur</option>
        <option
          v-for="(option, ix) in ilsaStore.dropDownContents?.odometerMax"
          :key="ix"
          :value="option.key"
          :disabled="!option.count"
        >
          {{ option.display_value }} ({{ option.count }})
        </option>
      </select>
    </div>

    <div v-if="appMode === AppMode.Used" class="flex flex-col gap-1">
      <label for="construction_year_min">Bouwjaar vanaf</label>
      <select
        id="construction_year_min"
        v-model="filterConstructionYearMin"
        class="bg-white border-cupgragray focus:ring-copper focus:border-copper"
      >
        <option :value="undefined">Geen voorkeur</option>
        <option
          v-for="(option, ix) in ilsaStore.dropDownContents?.constructionYearMin"
          :key="ix"
          :value="option.key"
          :disabled="!option.count"
        >
          {{ option.display_value }} ({{ option.count }})
        </option>
      </select>
    </div>

    <div class="flex flex-col gap-1">
      <label for="price_max">Prijs tot</label>
      <select
        id="price_max"
        v-model="filterPriceMax"
        class="bg-white border-cupgragray focus:ring-copper focus:border-copper"
      >
        <option :value="undefined">Geen voorkeur</option>
        <option
          v-for="(option, ix) in ilsaStore.dropDownContents?.priceMax"
          :key="ix"
          :value="option.key"
          :disabled="!option.count"
        >
          {{ option.display_value }} ({{ option.count }})
        </option>
      </select>
    </div>

    <div class="flex flex-col gap-1">
      <label for="colour">Kleur</label>
      <select
        id="colour"
        v-model="filterColour"
        class="bg-white border-cupgragray focus:ring-copper focus:border-copper"
      >
        <option :value="undefined">Geen voorkeur</option>
        <option
          v-for="(option, ix) in ilsaStore.dropDownContents?.colours"
          :key="ix"
          :value="option.key"
          :disabled="!option.count"
        >
          {{ option.display_value }} ({{ option.count }})
        </option>
      </select>
    </div>

    <div class="flex flex-col gap-1">
      <label for="keyword">Trefwoord</label>
      <input
        id="keyword"
        v-model="keyword"
        type="text"
        class="bg-white border-cupgragray focus:ring-copper focus:border-copper"
      />
    </div>

    <div v-if="appMode === AppMode.New && hasDiscountedCars" class="flex flex-col gap-1">
      <label>Korting</label>
      <select
        id="discount"
        v-model="filterDiscount"
        class="bg-white border-cupgragray focus:ring-copper focus:border-copper"
      >
        <option :value="false">Alle auto's</option>
        <option :value="true">
          Auto's met korting ({{ ilsaStore.dropDownContents?.isDiscounted || 0 }})
        </option>
      </select>
    </div>

    <div v-else-if="appMode !== AppMode.New" class="hidden lg:flex flex-col gap-1">
      <label for="postal_code">Postcode (cijfers)</label>
      <input
        id="postal_code"
        v-model="postalCode"
        type="text"
        inputmode="numeric"
        pattern="\d{4}"
        maxlength="4"
        onkeypress="return event.charCode >= 48 && event.charCode <= 57"
        class="bg-white border-cupgragray focus:ring-copper focus:border-copper"
      />
    </div>
    <div v-if="appMode !== AppMode.New" class="hidden lg:flex flex-col gap-1">
      <label for="distance">Maximale afstand</label>
      <select
        id="distance"
        v-model="filterDistanceMax"
        class="bg-white border-cupgragray focus:ring-copper focus:border-copper"
      >
        <option :value="undefined">Geen voorkeur</option>
        <option
          v-for="(option, ix) in ilsaStore.dropDownContents?.distanceMax"
          :key="ix"
          :value="option.key"
          :disabled="!option.count"
        >
          {{ option.display_value }} ({{ option.count }})
        </option>
      </select>
    </div>
    <!--
        <div class="flex flex-col gap-1">
          <label for="sorting">Sorteren</label>
          <select
            id="sorting"
            v-model="sorting"
            class="bg-white border-cupgragray focus:ring-copper focus:border-copper"
          >
            <option value="">Populariteit</option>
            <option value="prijs_op">Prijs oplopend</option>
            <option value="prijs_af">Prijs aflopend</option>
          </select>
        </div> -->
  </div>
</template>
