import { ref } from 'vue'
import { defineStore } from 'pinia'
import type { Vehicle } from '@/models/Vehicle'
import type { SearchResultsResponse } from '@/models/SearchResults'
import { useFiltersStore } from './filters'
import IlsaFilters from '@/services/IlsaService/IlsaFilters'
import IlsaService from '@/services/IlsaService'
import type { DropDownContentsResponse } from '@/models/DropDownContents'
import { AxiosError } from 'axios'
import config from '@/config'

const baseUrl = config.ilsaBaseUrl
const instance = config.ilsaInstance
const locale = 'nl_NL'
const ilsaService = new IlsaService(baseUrl, instance, locale)

export const useIlsaStore = defineStore('ilsa', () => {
  const filtersStore = useFiltersStore()

  const isLoading = ref(false)
  const isRetrievingMoreResults = ref(false)
  const loadingError = ref<number>()
  const searchResults = ref<SearchResultsResponse>()
  const vehicle = ref<Vehicle>()

  const dropDownContents = ref<DropDownContentsResponse>()

  const comparison = ref<any>(null)

  const compareVehicleIds = ref<Set<string>>(new Set<string>())
  const compareOnlyDifferences = ref<boolean>(true)

  function toggleVehicleIdForComparison(id: string) {
    if (compareVehicleIds.value.has(id)) {
      compareVehicleIds.value.delete(id)
    } else {
      compareVehicleIds.value.add(id)
    }
  }

  async function retrieveSearchResults() {
    isLoading.value = true
    loadingError.value = undefined

    const filters = filtersStore.filters

    let location
    if (filtersStore.postalCode?.length === 4) {
      location = `nl ${filtersStore.postalCode}`
    }

    try {
      const results = await Promise.all([
        ilsaService.searchresults(filters, 0, location),
        ilsaService.dropdowncontents(filters, IlsaFilters.DROPDOWN_FILTERS, location)
      ])
      searchResults.value = results[0]
      dropDownContents.value = results[1]
    } catch (e) {
      if (e instanceof AxiosError) {
        loadingError.value = e.response?.status
      } else {
        loadingError.value = 999
      }
      searchResults.value = undefined
      dropDownContents.value = undefined
    }

    isLoading.value = false
  }

  async function retrieveMoreSearchResults() {
    if (!searchResults.value || isRetrievingMoreResults.value) {
      // Don't have search results to expand, or already loading more
      return false
    }

    if (searchResults.value.results.length >= searchResults.value.num_results) {
      // All data is loaded
      return false
    }

    isRetrievingMoreResults.value = true
    loadingError.value = undefined

    try {
      const data = await ilsaService.searchresults(
        filtersStore.filters,
        searchResults.value.results.length
      )
      const newSearchResults = searchResults.value
      if (!newSearchResults) {
        // Something happened to the old search results in the meantime, cancel
        return false
      }

      newSearchResults.results.push(...data.results)

      searchResults.value = newSearchResults
    } catch (e) {
      if (e instanceof AxiosError) {
        loadingError.value = e.response?.status
      } else {
        loadingError.value = 999
      }
    }

    isRetrievingMoreResults.value = false
  }

  async function retrieveVehicle(id: string) {
    if (id.match(/^[0-9]{6}$/)) {
      return retrieveVehicleByReferenceNr(id)
    }

    vehicle.value = undefined
    loadingError.value = undefined
    isLoading.value = true

    try {
      const data = await ilsaService.vehicle(id)
      vehicle.value = data.vehicle
    } catch (e) {
      if (e instanceof AxiosError) {
        loadingError.value = e.response?.status
      } else {
        loadingError.value = 999
      }
      vehicle.value = undefined
    }

    isLoading.value = false
  }

  async function retrieveVehicleByReferenceNr(referenceNr: string) {
    vehicle.value = undefined
    loadingError.value = undefined
    isLoading.value = true

    try {
      const searchResults = await ilsaService.searchresults(new IlsaFilters({ referenceNr }))
      if (searchResults.results.length !== 1) {
        loadingError.value = 404
        isLoading.value = false
        return
      }

      const id = searchResults.results[0].id

      const data = await ilsaService.vehicle(id)
      vehicle.value = data.vehicle
    } catch (e) {
      if (e instanceof AxiosError) {
        loadingError.value = e.response?.status
      } else {
        loadingError.value = 999
      }
      vehicle.value = undefined
    }

    isLoading.value = false
  }

  async function retrieveComparison() {
    comparison.value = await ilsaService.compare(
      compareVehicleIds.value,
      compareOnlyDifferences.value
    )
  }

  return {
    isLoading,
    isRetrievingMoreResults,
    loadingError,
    searchResults,
    vehicle,
    dropDownContents,
    compareVehicleIds,
    compareOnlyDifferences,
    comparison,
    toggleVehicleIdForComparison,
    retrieveSearchResults,
    retrieveMoreSearchResults,
    retrieveVehicle,
    retrieveComparison
  }
})
