<script setup lang="ts">
import LeadForm from '@/components/LeadForm.vue'
import config from '@/config'
import { useHead, useSeoMeta } from '@unhead/vue'
import { useRoute } from 'vue-router'

const route = useRoute()

defineProps<{
  id: string
  garageId?: string
}>()

useHead({
  link: () => [
    {
      rel: 'canonical',
      href: `${import.meta.env.VITE_BASE_URL || `${window.location.protocol}//${window.location.host}`}${route.fullPath}`
    }
  ]
})

useSeoMeta({
  title: 'Interesse',
  ogTitle: `Interesse | ${config.appName}`,
  robots: 'noindex'
})
</script>

<template>
  <main>
    <div class="container mx-auto mt-4 px-4">
      <LeadForm :vehicle-id="id" :garage-id="garageId" :show-close="false" />
    </div>
  </main>
</template>
