<script setup lang="ts">
import type { Vehicle } from '@/models/Vehicle'
import AppButton from './AppButton.vue'
import TextField from './TextField.vue'
import axios from 'axios'
import { computed, ref } from 'vue'
import LoadingSpinner from './LoadingSpinner.vue'
import config, { AppMode } from '@/config'
import { useRoute } from 'vue-router'

interface CupraGarage {
  id: string
  name: string
  url: string
  phone: string
}

const route = useRoute()
const utmTags = computed(() =>
  Object.keys(route.query).reduce(
    (acc, key) => {
      if (key.match(/^utm_/) && typeof route.query[key] === 'string') {
        acc[key] = route.query[key]
      }
      return acc
    },
    {} as Record<string, string>
  )
)

const DEFAULT_VEHICLE_BRAND = config.brandName

const CUPRA_GARAGES: Array<CupraGarage> = [
  {
    id: '200411',
    name: 'Alkmaar',
    url: 'https://www.cupraofficial.nl/garage/alkmaar',
    phone: '072 879 66 67'
  },
  {
    id: '200791',
    name: 'Amsterdam',
    url: 'https://www.cupraofficial.nl/garage/amsterdam',
    phone: '020 238 63 29'
  },
  {
    id: '200242',
    name: 'Den Bosch',
    url: 'https://www.cupraofficial.nl/garage/den-bosch',
    phone: '073 646 66 06'
  },
  {
    id: '200262',
    name: 'Den Haag',
    url: 'https://www.cupraofficial.nl/garage/den-haag',
    phone: '070 342 94 44'
  },
  {
    id: '200405',
    name: 'Dordrecht',
    url: 'https://www.cupraofficial.nl/garage/dordrecht',
    phone: '088 374 97 65'
  },
  {
    id: '200398',
    name: 'Heerenveen',
    url: 'https://www.cupraofficial.nl/garage/heerenveen',
    phone: '0513 633 054'
  },
  {
    id: '200412',
    name: 'Hengelo',
    url: 'https://www.cupraofficial.nl/garage/hengelo',
    phone: '074 255 41 41'
  },
  {
    id: '200798',
    name: 'Leusden',
    url: 'https://www.cupraofficial.nl/garage/leusden',
    phone: '033 494 99 19'
  },
  {
    id: '200792',
    name: 'Rotterdam',
    url: 'https://www.cupraofficial.nl/garage/rotterdam',
    phone: '010 318 28 39'
  },
  {
    id: '200400',
    name: 'Tilburg',
    url: 'https://www.cupraofficial.nl/garage/tilburg',
    phone: '013 547 00 40'
  },
  {
    id: '200402',
    name: 'Zwolle',
    url: 'https://www.cupraofficial.nl/garage/zwolle',
    phone: '038 456 13 30'
  }
]

interface FormErrors {
  dealerId?: string
  firstName?: string
  lastname?: string
  email?: string
  phone?: string
  remarks?: string
  tradeInLicensePlate?: string
  tradeInOdometer?: string
}

const props = defineProps<{
  vehicleId: string
  vehicle?: Vehicle
  vehicleModel?: string
  garageId?: string
  showClose: boolean
}>()

const dealerId = ref<string | undefined>(undefined)
const cupraGarage = ref<CupraGarage | undefined>(undefined)

if (config.appMode === AppMode.Used) {
  // For used cars, always use the advertiser's Dealer ID
  dealerId.value = props.vehicle?.advertiser.id
} else if (config.appMode === AppMode.New) {
  // For new cars, pre-set the Dealer ID when a used landed with a predefined Dealer ID
  if (props.garageId && CUPRA_GARAGES.some((el) => el.id === props.garageId)) {
    dealerId.value = props.garageId
    cupraGarage.value = CUPRA_GARAGES.find((el) => el.id === props.garageId)
  }
}

const firstName = ref<string>('')
const lastName = ref<string>('')
const email = ref<string>('')
const phone = ref<string>('')
const remarks = ref<string>('')
const testDrive = ref<boolean>(false)
const tradeIn = ref<boolean>(false)
const tradeInLicensePlate = ref<string>('')
const tradeInOdometer = ref<string>('')

const isSubmitting = ref<boolean>(false)
const isSubmitted = ref<boolean>(false)
const formErrors = ref<FormErrors>({})
const apiError = ref<string | undefined>(undefined)

defineEmits(['close'])

const validateForm = (): boolean => {
  const newFormErrors: FormErrors = {}
  let hasErrors = false

  if (config.appMode === AppMode.New && !dealerId.value) {
    newFormErrors.dealerId = 'Kies een CUPRA Garage'
    hasErrors = true
  }

  if (firstName.value.length < 2) {
    newFormErrors.firstName = 'Vul je voornaam in'
    hasErrors = true
  }
  if (lastName.value.length < 2) {
    newFormErrors.lastname = 'Vul je achternaam in'
    hasErrors = true
  }
  // if (phone.value.length !== 10) {
  //   newFormErrors.phone = 'Vul een geldig telefoonnummer in'
  //   hasErrors = true
  // }
  if (email.value.length < 6 || !email.value.includes('@') || !email.value.includes('.')) {
    newFormErrors.email = 'Vul een geldig e-mailadres in'
    hasErrors = true
  }
  if (email.value.indexOf('+') > -1) {
    newFormErrors.email = 'Vul een e-mailadres zonder plus-teken in'
    hasErrors = true
  }

  if (hasErrors) {
    window.utag &&
      window.utag.link({
        tealium_event: 'form_error',
        url_of_page: window.location.href,
        brand: props.vehicle?.general.make.name || DEFAULT_VEHICLE_BRAND,
        eventCategory: 'Lead form',
        car_model: props.vehicle?.general.model.name,
        car_model_style: props.vehicle?.general.type.name,
        form_name: 'Lead form'
      })
  }

  formErrors.value = newFormErrors

  return !hasErrors
}

const submitLead = async () => {
  if (!validateForm()) {
    return
  }

  isSubmitted.value = false
  isSubmitting.value = true

  const payload = {
    url: window.location.href,
    vin: props.vehicle?.identification.vin,
    dealerId: config.appMode === AppMode.Used ? props.vehicle?.advertiser.id : dealerId.value!,
    vehicleIsUsed: props.vehicle?.condition.used,
    vehicleLicensePlate:
      props.vehicle?.identification.license_plate?.raw ||
      props.vehicle?.identification.license_plate?.formatted,
    vehicleReference: props.vehicle?.identification.referencenumber || props.vehicleId,
    vehicleBrand: props.vehicle?.general.make.name || DEFAULT_VEHICLE_BRAND,
    vehicleModel: props.vehicle?.general.model.name || props.vehicleModel,
    vehicleDescription: props.vehicle
      ? `${props.vehicleId} - ${props.vehicle.general.make.name} ${props.vehicle.general.model.name} ${props.vehicle.general.type.name} (${props.vehicle.body.colour.formatted})`
      : props.vehicleId,
    firstName: firstName.value,
    lastName: lastName.value,
    email: email.value,
    phone: phone.value,
    remarks: remarks.value,
    testDrive: testDrive.value,
    tradeIn: tradeIn.value,
    tradeInLicensePlate: tradeInLicensePlate.value,
    tradeInOdometer: tradeInOdometer.value,
    utmTags: utmTags.value
  }

  try {
    await axios.post('/api/submitlead', payload, {
      headers: {
        'X-API-Key': config.apiKey
      }
    })
  } catch (e) {
    apiError.value = `${e}`

    window.utag &&
      window.utag.link({
        tealium_event: 'form_error',
        url_of_page: window.location.href,
        brand: props.vehicle?.general.make.name || DEFAULT_VEHICLE_BRAND,
        eventCategory: 'Lead form',
        car_model: props.vehicle?.general.model.name,
        car_model_style: props.vehicle?.general.type.name,
        form_name: 'Lead form',
        form_field_error: 'apiError',
        form_error_message: `${e}`
      })

    return
  }

  window.utag &&
    window.utag.link({
      tealium_event: 'form_submit',
      url_of_page: window.location.href,
      brand: props.vehicle?.general.make.name || DEFAULT_VEHICLE_BRAND,
      eventCategory: 'Lead form',
      car_model: props.vehicle?.general.model.name,
      car_model_style: props.vehicle?.general.type.name,
      form_name: 'Lead form',
      dealerId: props.vehicle?.advertiser.id
    })
  isSubmitted.value = true
  isSubmitting.value = false
}
</script>

<template>
  <h1 class="text-3xl mb-3">Bedankt voor je interesse!</h1>

  <LoadingSpinner v-if="isSubmitting" />

  <div v-else-if="isSubmitted">
    <p v-if="config.appMode === AppMode.New">
      Bedankt voor je aanvraag, de CUPRA Specialist neemt zo spoedig mogelijk contact met je op.
    </p>
    <p v-else>Bedankt voor je aanvraag, de verkoper neemt zo spoedig mogelijk contact met je op.</p>

    <AppButton type="button" variant="primary" @click="$emit('close')">Sluiten</AppButton>
  </div>

  <div v-else>
    <p v-if="config.appMode === AppMode.Used && !!vehicle">
      Vul het onderstaande formulier in zodat we contact met je kunnen opnemen, of bel direct met
      {{ vehicle.advertiser.name }} via
      <a
        :href="`tel:${vehicle.advertiser.telephone.replace(' ', '')}`"
        class="font-medium underline"
      >
        {{ vehicle.advertiser.telephone }}</a
      >.
    </p>
    <p v-else>
      Vul het onderstaande formulier in zodat we contact met je kunnen opnemen, of bel direct met
      <template v-if="cupraGarage">
        <a v-if="cupraGarage" :href="cupraGarage.url" class="font-medium underline" target="_blank"
          >CUPRA Garage {{ cupraGarage.name }}</a
        >
        via
        <a
          v-if="cupraGarage"
          :href="`tel:${cupraGarage.phone.replace(' ', '')}`"
          class="font-medium underline"
        >
          {{ cupraGarage.phone }}</a
        >.
      </template>
      <template v-else>
        <a href="https://www.cupraofficial.nl/garage" class="font-medium underline" target="_blank"
          >de CUPRA Garage van je voorkeur</a
        >.
      </template>
    </p>
    <div class="mt-8" v-if="!!vehicle">
      <div class="text-sm">Interesse in:</div>
      <div class="font-medium">
        {{ vehicle.general.make.name }} {{ vehicle.general.model.name }}
        {{ vehicle.general.type.name }} ({{ vehicle.body.colour.formatted }}) - Referentie:
        {{ vehicle.identification.referencenumber }}
      </div>
    </div>
    <form @submit.prevent="submitLead">
      <div class="grid md:grid-cols-2 gap-2 mt-8">
        <div
          v-if="config.appMode === AppMode.New && !cupraGarage"
          class="md:col-span-2 flex flex-col"
        >
          <label for="cupraGarage" class="text-sm uppercase mb-0.5">CUPRA Garage</label>
          <select
            v-model="dealerId"
            id="cupraGarage"
            class="border-0 border-b border-b-gray-200 focus:ring-0 border-transparent focus:border-copper text-cupragray h-12"
            :class="{ 'border-b-red-700': formErrors.dealerId }"
          >
            <option :value="undefined" disabled>Kies een CUPRA Garage</option>
            <option v-for="garage in CUPRA_GARAGES" :key="garage.id" :value="garage.id">
              {{ garage.name }}
            </option>
          </select>

          <div v-if="formErrors.dealerId" class="text-xs text-red-700">
            {{ formErrors.dealerId }}
          </div>
          <div v-else class="text-xs">&nbsp;</div>
        </div>
        <TextField
          label="Voornaam"
          type="text"
          autocomplete="given-name"
          v-model="firstName"
          :error="formErrors.firstName"
        />
        <TextField
          label="Achternaam"
          type="text"
          autocomplete="family-name"
          v-model="lastName"
          :error="formErrors.lastname"
        />
        <TextField
          label="E-mailadres"
          type="email"
          autocomplete="email"
          v-model="email"
          :error="formErrors.email"
        />
        <TextField
          label="Telefoonnummer (optioneel)"
          type="tel"
          autocomplete="tel"
          v-model="phone"
          :error="formErrors.phone"
        />

        <TextField
          class="md:col-span-2"
          label="Opmerkingen (optioneel)"
          type="text"
          v-model="remarks"
          :error="formErrors.remarks"
        />
        <label class="mt-4 md:col-span-2"
          ><input
            type="checkbox"
            class="text-copper mr-2 mb-1 focus:outline-copper"
            :value="true"
            v-model="testDrive"
          />
          Ik heb interesse in een proefrit
        </label>

        <label class="mt-4 md:col-span-2"
          ><input
            type="checkbox"
            class="text-copper mr-2 mb-1 focus:outline-copper"
            :value="true"
            v-model="tradeIn"
          />
          Ik heb interesse in inruilen
        </label>

        <template v-if="tradeIn">
          <TextField
            label="Kenteken (optioneel)"
            type="text"
            v-model="tradeInLicensePlate"
            :error="formErrors.tradeInLicensePlate"
          />
          <TextField
            label="Kilometerstand ± (optioneel)"
            type="number"
            v-model="tradeInOdometer"
            :error="formErrors.tradeInOdometer"
          />
        </template>
      </div>

      <AppButton variant="primary" class="mt-8" type="submit">
        Neem contact met mij op &gt;
      </AppButton>
    </form>
  </div>
</template>
