<script setup lang="ts">
import { computed, ref, watch } from 'vue'
import { useIlsaStore } from '@/stores/ilsa'
import config, { AppMode } from '@/config'

import 'vue-json-pretty/lib/styles.css'

import { PhoneIcon } from '@heroicons/vue/24/solid'
import ErrorMessage from '@/components/ErrorMessage.vue'
import LeadForm from '@/components/LeadForm.vue'
import ModalPart from '@/components/ModalPart.vue'
import ImageSlider from '@/components/ImageSlider.vue'
import FooterPart from '@/components/FooterPart.vue'
import DetailField from '@/components/DetailField.vue'
import { useHead, useSeoMeta } from '@unhead/vue'
import { useRoute, useRouter } from 'vue-router'

const EQUIPMENT_KEY = 'equipment'

const props = defineProps<{
  id: string
  slug: string
  model: string
  garageId?: string
}>()

const route = useRoute()
const router = useRouter()

const ilsaStore = useIlsaStore()

watch(ilsaStore, (ilsaStore) => {
  if (ilsaStore.loadingError === 404 && props.garageId) {
    // Car not found but this was a deep link with Garage ID for leads. Use standalone lead form
    router.replace({ path: `/interesse/${props.id}/${props.garageId}` })
  }
})

ilsaStore.retrieveVehicle(props.id)

useHead({
  link: () => {
    if (!ilsaStore.vehicle) return []
    return [
      {
        rel: 'canonical',
        href: `${import.meta.env.VITE_BASE_URL || `${window.location.protocol}//${window.location.host}`}/${ilsaStore.vehicle.general.model.normalized.key.replace('cupra/', '')}/${ilsaStore.vehicle.identification.slug}/${ilsaStore.vehicle.id}`
      }
    ]
  },
  script: () =>
    ilsaStore.vehicle
      ? [
          {
            hid: 'json-ld-product',
            type: 'application/ld+json',
            textContent: JSON.stringify({
              '@context': 'https://schema.org/',
              '@type': 'Product',
              name: `${ilsaStore.vehicle.general.year} ${ilsaStore.vehicle.general.make.name} ${ilsaStore.vehicle.general.model.name} ${ilsaStore.vehicle.general.type.name}`,
              image: ilsaStore.vehicle.images.map((i) => i.src),
              // description: `${ilsaStore.vehicle.general.year} ${ilsaStore.vehicle.general.make.name} ${ilsaStore.vehicle.general.model.name} ${ilsaStore.vehicle.general.type.name}`,
              sku: ilsaStore.vehicle.id,
              // mpn: '925872',
              brand: {
                '@type': 'Brand',
                name: ilsaStore.vehicle.general.make.name
              },
              color: ilsaStore.vehicle.body.colour.formatted,
              offers: {
                '@type': 'Offer',
                url: `${import.meta.env.VITE_BASE_URL || `${window.location.protocol}//${window.location.host}`}${route.fullPath}`,
                priceCurrency: 'EUR',
                price: ilsaStore.vehicle.sales_conditions.pricing.asking.amount,
                // priceValidUntil: '2024-11-20',
                itemCondition: ilsaStore.vehicle.condition.used
                  ? 'https://schema.org/UsedCondition'
                  : 'https://schema.org/NewCondition',
                availability: 'https://schema.org/InStock'
              }
            })
          },
          {
            hid: 'json-ld-car',
            type: 'application/ld+json',
            textContent: JSON.stringify({
              '@context': 'https://schema.org',
              '@type': 'Car',
              name: `${ilsaStore.vehicle.general.year} ${ilsaStore.vehicle.general.make.name} ${ilsaStore.vehicle.general.model.name} ${ilsaStore.vehicle.general.type.name}`,
              vehicleIdentificationNumber: 'XXXXX11XXXX000000',
              image: ilsaStore.vehicle.images.map((i) => i.src),
              url: `${import.meta.env.VITE_BASE_URL || `${window.location.protocol}//${window.location.host}`}${route.fullPath}`,
              offers: {
                '@type': 'Offer',
                availability: 'https://schema.org/InStock',
                price: ilsaStore.vehicle.sales_conditions.pricing.asking.amount,
                priceCurrency: 'EUR'
              },
              itemCondition: ilsaStore.vehicle.condition.used
                ? 'https://schema.org/UsedCondition'
                : 'https://schema.org/NewCondition',
              brand: {
                '@type': 'Brand',
                name: ilsaStore.vehicle?.general.make.name
              },
              model: ilsaStore.vehicle?.general.model.name,
              vehicleConfiguration: ilsaStore.vehicle.general.type.name,
              vehicleModelDate: ilsaStore.vehicle?.general.year,
              mileageFromOdometer: {
                '@type': 'QuantitativeValue',
                value: ilsaStore.vehicle?.condition.odometer.value,
                unitCode: 'KMT'
              },
              color: ilsaStore.vehicle.body.colour.formatted,
              vehicleInteriorColor: ilsaStore.vehicle.interior?.colour?.name,
              vehicleInteriorType: ilsaStore.vehicle.interior?.upholstery?.name?.display_value,
              // bodyType: 'Pickup',
              // driveWheelConfiguration: 'https://schema.org/FourWheelDriveConfiguration',
              vehicleEngine: {
                '@type': 'EngineSpecification',
                fuelType: ilsaStore.vehicle.powertrain?.engine?.energy?.type?.code.display_value
              },
              vehicleTransmission: ilsaStore.vehicle.powertrain?.transmission?.formatted,
              numberOfDoors: ilsaStore.vehicle.body.door_count,
              vehicleSeatingCapacity: ilsaStore.vehicle.interior?.seat_count
            })
          }
        ]
      : []
})

const pageTitle = computed(() => {
  if (!ilsaStore.vehicle) return null
  return [
    ilsaStore.vehicle.general.make.name,
    ilsaStore.vehicle.general.model.name,
    ilsaStore.vehicle.general.type.name
  ].join(' ')
})

const pageDescription = computed(() => {
  switch (config.appMode) {
    case AppMode.New:
      return ilsaStore.vehicle
        ? `Rijd binnen 30 dagen in deze nieuwe ${ilsaStore.vehicle.general.make.name} ${ilsaStore.vehicle.general.model.name}.`
        : null
    case AppMode.Used:
      return ilsaStore.vehicle
        ? `Rijd snel in deze CUPRA Approved occasion voor ${ilsaStore.vehicle.sales_conditions.pricing.asking.formatted}.`
        : null
    default:
      return null
  }
})

useSeoMeta({
  title: () => pageTitle.value,
  ogTitle: () => (pageTitle.value ? `${pageTitle.value} | ${config.appName}` : config.appName),
  description: () => pageDescription.value,
  ogDescription: () => pageDescription.value,
  ogImage: () => ilsaStore.vehicle?.images[0].src,
  robots: () => (ilsaStore.vehicle ? null : 'noindex')
})

const vehicleEquipment = computed(() =>
  ilsaStore.vehicle?.sections.find((section) => section.key === EQUIPMENT_KEY)
)

const dateFormatter = new Intl.DateTimeFormat('nl-NL', { dateStyle: 'full' })
const formattedExpectedDeliveryDate = computed(() => {
  const now = new Date()
  let expectedDeliveryDate = new Date(now.setDate(now.getDate() + 30))
  if (expectedDeliveryDate.getDay() === 0) {
    expectedDeliveryDate.setDate(expectedDeliveryDate.getDate() + 1)
  }

  return dateFormatter.format(expectedDeliveryDate)
})

const promoLabel = computed(() => {
  if (!ilsaStore.vehicle) return null
  const vehicle = ilsaStore.vehicle

  if (!vehicle.sales_conditions.pricing.asking.before_discount?.amount) {
    return null
  }

  const discount =
    vehicle.sales_conditions.pricing.asking.before_discount?.amount -
    vehicle.sales_conditions.pricing.asking.amount

  if (!discount) {
    return null
  }

  const formattedDiscount = new Intl.NumberFormat('nl-NL', {
    style: 'currency',
    currency: 'EUR',
    maximumFractionDigits: 0
  }).format(discount)
  return `${formattedDiscount} korting`
})

const trackPhoneCall = (label: string, url: string) => {
  window.utag &&
    window.utag.link({
      tealium_event: 'button_click',
      button_category: 'ui interaction',
      link_text: label,
      link_urk: url,
      link_placement: 'other',
      eventCategory: 'button_click'
    })
}

const isLeadModalVisible = ref(!!props.garageId)

const showLeadForm = () => {
  window.utag &&
    window.utag.link({
      tealium_event: 'button_click',
      button_category: 'ui interaction',
      link_text: 'Neem contact op',
      link_placement: 'other',
      eventCategory: 'button_click'
    })
  window.utag &&
    window.utag.link({
      tealium_event: 'form_start',
      url_of_page: window.location.href,
      brand: ilsaStore.vehicle?.general.make.name,
      eventCategory: 'Lead form',
      car_model: ilsaStore.vehicle?.general.model.name,
      car_model_style: ilsaStore.vehicle?.general.type.name,
      form_name: 'Lead form'
    })

  window.fbq && window.fbq('trackCustom', 'voorraad_doorklik')

  isLeadModalVisible.value = true
}

const testdriveUrl = computed(() => {
  const url = new URL('https://mijn.cupraofficial.nl/plan_nu_jouw_proefrit')
  url.searchParams.append('campaign_id', '7014H0000002dobQAA')

  // props.sourceLocationId && url.searchParams.append('locatie', props.sourceLocationId);

  if (ilsaStore.vehicle?.general.model.name) {
    const modelType = config.testDrivePlannerModelCodes[ilsaStore.vehicle.general.model.name]
    if (modelType) {
      url.searchParams.append('model', modelType)
    }
  }

  return url.toString()
})
function showTestdrivePlanner() {
  window.utag &&
    window.utag.link({
      tealium_event: 'button_click',
      button_category: 'navigation',
      link_url: testdriveUrl.value,
      link_text: 'Plan een proefrit',
      link_placement: 'other',
      eventCategory: 'button_click'
    })

  window.fbq && window.fbq('trackCustom', 'voorraad_doorklik')

  window.open(testdriveUrl.value, '_blank', 'noopener')
}
</script>

<template>
  <ModalPart
    :show="isLeadModalVisible"
    @close="isLeadModalVisible = false"
    v-if="ilsaStore.vehicle"
  >
    <LeadForm
      :vehicle-id="id"
      :garage-id="garageId"
      :vehicle="ilsaStore.vehicle"
      :show-close="true"
      @close="isLeadModalVisible = false"
    />
  </ModalPart>

  <ErrorMessage :error="ilsaStore.loadingError" />

  <main v-if="ilsaStore.vehicle">
    <div class="container mx-auto mt-4 px-4">
      <a @click.prevent="$router.back()" class="text-sm cursor-pointer hover:underline block mb-4"
        >&lt; Terug naar overzicht</a
      >
      <div class="grid grid-cols-1 lg:grid-cols-8 gap-3 lg:gap-8">
        <div class="lg:col-span-5">
          <ImageSlider :vehicle="ilsaStore.vehicle" />
        </div>

        <div class="lg:col-span-3">
          <h1 class="font-light text-4xl md:mt-4 xl:mt-16">
            {{ ilsaStore.vehicle.general.make.name }} {{ ilsaStore.vehicle.general.model.name }}
          </h1>
          <h2 class="mt-3 text-xl font-semibold">
            {{ ilsaStore.vehicle.general.type.name }}
          </h2>
          <div>
            {{ ilsaStore.vehicle.body.colour.formatted }}
          </div>

          <div
            v-if="ilsaStore.vehicle!.sales_conditions.pricing.asking.before_discount?.formatted"
            class="mt-8"
          >
            <div
              v-if="promoLabel"
              class="mb-3 inline-block px-2 py-1 bg-copper rounded font-semibold text-sm uppercase text-white"
            >
              {{ promoLabel }}
            </div>
            <div class="mb-2 text-sm">
              Van
              <span class="line-through font-bold">
                {{ ilsaStore.vehicle.sales_conditions.pricing.asking.before_discount.formatted }}
              </span>
            </div>

            Nu rijklaar voor:
            <h3 class="text-3xl font-bold">
              {{ ilsaStore.vehicle.sales_conditions.pricing.asking.formatted }}
            </h3>
          </div>
          <div v-else class="mt-8">
            Rijklaar voor:
            <h3 class="text-3xl font-bold">
              {{ ilsaStore.vehicle.sales_conditions.pricing.asking.formatted }}
            </h3>
          </div>
          <div class="mt-2 text-sm">Inclusief btw, bpm, garantie, rijklaarmaakkosten en leges</div>
          <div
            v-if="config.plPromo.includes(ilsaStore.vehicle.identification.production_number)"
            class="md:w-96 p-2 mt-4 mb-2 border border-black leading-5"
          >
            <h3 class="font-medium">&rarr; Private Lease vanaf €459 per maand</h3>
            <a href="#" class="text-sm" @click.prevent="showLeadForm"
              >Neem contact op met de CUPRA Specialist voor meer informatie.</a
            >
          </div>
          <div
            v-if="config.appMode === AppMode.New"
            class="md:w-96 border-petrol pt-2 mb-4 text-sm"
            :class="{
              'border-t mt-4': !config.plPromo.includes(
                ilsaStore.vehicle.identification.production_number
              )
            }"
          >
            ✓ Vandaag bestellen is
            <span class="font-semibold">{{ formattedExpectedDeliveryDate }}</span> rijden*
          </div>
          <div v-else class="md:w-96 border-t border-petrol pt-2 mt-4 mb-4">
            <h4 class="font-bold">Wordt verkocht door:</h4>
            {{ ilsaStore.vehicle.advertiser.name }}<br />
            {{ ilsaStore.vehicle.advertiser.address }}<br />
            {{ ilsaStore.vehicle.advertiser.city }}<br />
            <br />

            <a
              :href="`tel:${ilsaStore.vehicle.advertiser.telephone}`"
              @click="
                trackPhoneCall(
                  ilsaStore.vehicle.advertiser.telephone,
                  `tel:${ilsaStore.vehicle.advertiser.telephone}`
                )
              "
              class="text-copper hover:text-underline"
              ><PhoneIcon class="h-4 w-4 inline-block" />
              {{ ilsaStore.vehicle.advertiser.telephone }}</a
            >
          </div>

          <div class="hidden xl:block">
            <button
              class="text-center border border-petrol bg-petrol text-oxygen font-medium mt-12 mb-4 py-3 w-96"
              @click="showLeadForm"
            >
              Neem contact op &gt;
            </button>
          </div>
          <div v-if="config.appMode === AppMode.New" class="hidden xl:block">
            <a
              :href="testdriveUrl"
              target="_blank"
              class="block text-center border border-black bg-black text-oxygen font-medium my-4 mb-4 py-3 w-96"
              @click.prevent="showTestdrivePlanner"
            >
              Plan een proefrit &gt;
            </a>
          </div>
        </div>
      </div>
    </div>

    <!-- Wrap all details so sticky lead form button stays below the fold -->
    <div>
      <div class="my-8 py-4">
        <div class="container mx-auto px-4">
          <h2 class="text-3xl">Kenmerken</h2>

          <div
            v-for="(section, ix) in ilsaStore.vehicle.sections.filter(
              (section) => section.key !== EQUIPMENT_KEY
            )"
            :key="ix"
            class="grid lg:grid-cols-4 mt-8 border-t pt-1"
          >
            <div class="text-xl font-bold">{{ section.title }}</div>
            <div v-for="(subSection, ix) in section.subsections" :key="ix" class="col-span-3">
              <div v-if="subSection.text.html" v-html="subSection.text.html" />
              <div v-if="subSection.fields.length" class="grid lg:grid-cols-3">
                <DetailField
                  v-for="(field, ix) in subSection.fields.filter(
                    (field) => field.key != 'advertiser.email'
                  )"
                  :key="ix"
                  :label="field.label"
                  :value="field.display_value"
                  :url="field.url"
                />
              </div>
              <ul v-else-if="subSection.features.length">
                <li v-for="(feature, ix) in subSection.features" :key="ix">
                  {{ feature.label }}
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>

      <div v-if="vehicleEquipment" class="container mx-auto px-4">
        <div class="my-8 pb-6">
          <h2 class="text-3xl">Opties</h2>
          <div>
            <div
              v-for="(subSection, ix) in vehicleEquipment.subsections"
              :key="ix"
              class="grid lg:grid-cols-4 mt-8 border-t pt-1"
            >
              <div class="text-xl font-bold">{{ subSection.title }}</div>
              <div class="col-span-3 grid xl:grid-cols-2 text-sm">
                <div v-for="(feature, ix) in subSection.features" :key="ix">
                  • {{ feature.label }}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- sticky CTA's which become visible after scrolling -->
      <div
        class="hidden md:flex sticky bottom-0 py-6 flex-col md:flex-row justify-end gap-2 md:gap-8 container mx-auto px-4"
      >
        <button
          class="block flex-1 px-12 text-center bg-petrol text-oxygen font-medium py-3"
          @click="showLeadForm"
        >
          Neem contact op &gt;
        </button>
        <a
          v-if="config.appMode === AppMode.New"
          class="block flex-1 text-center bg-cupragray text-oxygen dark:border dark:border-cupragray font-medium py-3"
          :href="testdriveUrl"
          target="_blank"
          @click.prevent="showTestdrivePlanner"
        >
          Plan een proefrit &gt;
        </a>
      </div>
    </div>

    <!-- always visible sticky CTA's -->
    <div
      class="md:hidden sticky bottom-0 py-6 flex flex-col md:flex-row justify-center gap-2 md:gap-8 container mx-auto px-4"
    >
      <button
        class="block flex-1 text-center bg-petrol text-oxygen font-medium py-3"
        @click="showLeadForm"
      >
        Neem contact op &gt;
      </button>
      <a
        v-if="config.appMode === AppMode.New"
        class="block flex-1 text-center bg-cupragray text-oxygen dark:border dark:border-cupragray font-medium py-3"
        :href="testdriveUrl"
        target="_blank"
        @click.prevent="showTestdrivePlanner"
      >
        Plan een proefrit &gt;
      </a>
    </div>
  </main>

  <FooterPart />
</template>
