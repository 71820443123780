export const enum AppMode {
  New = 'new',
  Used = 'used'
}

const appMode: AppMode = (import.meta.env.VITE_APP_MODE as AppMode) || AppMode.New
let appName: string = 'CUPRA'
switch (appMode) {
  case AppMode.New:
    appName = 'CUPRA Voorraad'
    break
  case AppMode.Used:
    appName = 'CUPRA Approved occasions'
    break
  default:
    appName = 'CUPRA'
    break
}

const isProd: boolean = import.meta.env.PROD
const baseUrl: string = import.meta.env.BASE_URL
const apiKey: string = 'N56Z9DXce7fwcs'

const brandName: string = 'CUPRA'

const ilsaBaseUrl: string = 'https://api-nl.ilsa.cloud'
const ilsaInstance: string = import.meta.env.VITE_ILSA_INSTANCE

const testDrivePlannerModelCodes: Record<string, string> = {
  Born: 'MODELTYPE_CUPRA_BORN',
  Leon: 'MODELTYPE_CUPRA_LEON',
  'Leon Sportstourer': 'MODELTYPE_CUPRA_LEONST',
  Formentor: 'MODELTYPE_FORMENTOR',
  Tavascan: 'MODELTYPE_TAVASCAN',
  Terramar: 'MODELTYPE_CUPRATERRA'
}

let generalPromo: string | undefined = undefined
if (appMode === AppMode.New) {
  generalPromo =
    'Nu tijdelijk €1.500 inruilpremie en financial lease tarief met 5,49% rente op alle modellen'
}

let plPromo: Array<string> = []
if (appMode === AppMode.New) {
  plPromo = [
    '366207',
    '383765',
    '383762',
    '317897',
    '366203',
    '383761',
    '357167',
    '366217',
    '366205',
    '385606',
    '385635'
  ]
}

export default {
  appName,
  appMode,
  isProd,
  baseUrl,
  apiKey,
  brandName,
  ilsaBaseUrl,
  ilsaInstance,
  testDrivePlannerModelCodes,
  generalPromo,
  plPromo
}
